import { Container, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import backgroundHeader from '../../images/Home/prod-and-serv-bg-header.jpg'
import iconClassification from '../../images/Icon/Product/Classification.svg'
import iconFindAPart from '../../images/Icon/Product/Find-a-Part.svg'
import iconManufacturer from '../../images/Icon/Product/Manufacturer.svg'
import iconONN from '../../images/Icon/Product/ONN.svg'
import iconIQexplorer from '../../images/Icon/Product/iQE.svg'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'

/////////////////////////
// items data
/////////////////////////
const prodAndServItem = [
  {
    title: 'Find a Part',
    detail: 'Search our database of +1,000,000 orthopedic parts in seconds.',
    icon: iconFindAPart,
  },
  {
    title: 'ONN Newsletter',
    detail: 'Catch the latest in orthopedics & stay current in the industry.',
    icon: iconONN,
  },
  {
    title: 'Curvo benchmarking',
    detail:
      'Determine a competitive cost for all your products with the latest price points from a network of +450 hospitals',
    icon: iconIQexplorer,
  },
  {
    title: 'Manufacturer / Financial Services',
    detail: 'Gain the market insights on orthopedic products & companies you need.',
    icon: iconManufacturer,
  },
  {
    title: 'GIC Classification',
    detail:
      'Experience a true classification system, finding the orthopedic parts you need in seconds.',
    icon: iconClassification,
  },
]

/////////////////////////
// styled components
/////////////////////////
const Background = styled.div`
  background-image: url(${backgroundHeader});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 2688px;

  margin-bottom: 88px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 40px;
    background-size: 2100px;
  }
`
const HeaderContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-top: 156px;
  margin-bottom: 256px;

  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-top: 152px;
    margin-bottom: 236px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 106px;
    margin-bottom: 130px;
  }
`
const HeaderTitle = styled(NormalText)`
  border-left: white 3px solid;

  margin-bottom: 34px;
  padding-left: 20px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 26px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding-left: 16px;
    margin-bottom: 20px;
  }
`
const HeaderDetail = styled(NormalText)`
  max-width: 620px;
  text-align: center;
`
const CustomGrid = styled(Flex)`
  flex-direction: column;
  margin-bottom: -64px;

  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-right: 0;
    margin-bottom: -56px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: -48px;
  }
`
const RowWrapper = styled(Flex)`
  margin-bottom: 64px;
  margin-right: -100px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 56px;
    margin-right: 0;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: auto;
    flex: 1;
    margin-bottom: 0;
    flex-direction: column;
  }
`

const ItemContainer = styled(Flex)`
  align-items: center;
  width: 530px;
  margin-right: 100px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    width: 464px;
    margin-right: 0;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    width: auto;
    flex: 1;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: auto;
    flex: 1;
    margin-bottom: 48px;
  }
`

const ItemIcon = styled.div`
  line-height: 0;
  border-radius: 50%;
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;

  padding: 30px;
  margin-right: 32px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    padding: 20px;
    margin-right: 24px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding: 14px;
    margin-right: 24px;
  }
  img {
    margin: 0;
    width: 40px;
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      width: 32px;
    }
  }
`

/////////////////////////
// Home Products and Services Class
/////////////////////////
const HomeProductsAndServices: React.SFC = () => (
  <Background>
    <Container flexDirection="column" alignItems="center" marginBottom="80px">
      <HeaderContainer>
        <HeaderTitle white className="section-title">
          Find Your Fit
        </HeaderTitle>
        <HeaderDetail white>
          We’ve developed a series of products and services specifically for your hospital’s needs.
        </HeaderDetail>
      </HeaderContainer>
      <Flex flexDirection="column" alignItems="center">
        {!!prodAndServItem.length && (
          <CustomGrid>
            {prodAndServItem
              .reduce<any[][]>((preVal, currVal, curIndex) => {
                if (curIndex % 2 === 0) {
                  preVal.push([currVal])
                } else {
                  preVal[Math.floor(curIndex / 2)].push(currVal)
                }
                return preVal
              }, [])
              .map((group, groupIndex) => (
                <RowWrapper key={'Prod_and_Service' + groupIndex}>
                  {group.map((item, itemIndex) => (
                    <ItemContainer key={'item' + itemIndex}>
                      <ItemIcon>
                        <img src={item.icon} />
                      </ItemIcon>
                      <Flex flexDirection="column" flex="1">
                        <NormalText className="small-title" marginBottom="10px">
                          {item.title}
                        </NormalText>
                        <NormalText secondary style={{ alignSelf: 'stretch' }}>
                          {item.detail}
                        </NormalText>
                      </Flex>
                    </ItemContainer>
                  ))}
                </RowWrapper>
              ))}
          </CustomGrid>
        )}
      </Flex>
    </Container>
  </Background>
)

export default HomeProductsAndServices
