import { Button, Container, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import env from '../../config/env'
import backgroundRect from '../../images/CommonBackground/bg-small-rect.svg'
import backgroundPic from '../../images/Home/collage.png'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const Background = styled.div`
  background-image: url(${backgroundRect});
  background-repeat: no-repeat;
  background-size: 228px auto;
  background-position: -114px 90%;

  margin-top: 58px;

  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    background-size: 172px auto;
    background-position: -86px 90%;
    margin-bottom: 28px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    background-image: none;
  }
`
const InnerContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 180px;
  margin-left: 20px;
  width: 100%;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 100px;
    margin-left: 16px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 56px;
    margin-left: 0px;
    align-items: center;
  }
`
const AbstractPara = styled.div`
  max-width: 640px;
  margin-bottom: 48px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 414px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
    margin-bottom: 42px;
    * {
      text-align: center;
    }
  }
`
const TitleText = styled(NormalText)`
  margin-bottom: 34px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 20px;
  }
`
const ButtonsGroup = styled(Flex)`
  flex-wrap: wrap;

  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  button {
    width: 226px;
    height: 60px;

    margin-right: 26px;
    border: none;
    border-radius: 3px;

    @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
      margin-right: 16px;
    }
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      margin-right: 0px;
    }
  }
`

const AbstractImgContainer = styled.div`
  position: relative;
  width: 100%;
  img {
    position: absolute;
    width: 640px;
    top: -78px;
    left: 50%;
    @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
      width: 480px;
      top: -36px;
      left: 526px;
    }
    @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
      left: 350px;
    }
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      position: initial;
      width: 100%;
      top: 0;
      left: 0;
      margin-bottom: 48px;
    }
  }
`

/////////////////////////
// Home Abstract Class
/////////////////////////
const HomeAbstract: React.SFC = () => (
  <Background>
    <Container>
      <InnerContainer>
        <AbstractImgContainer>
          <img src={backgroundPic} />
        </AbstractImgContainer>
        <AbstractPara>
          <TitleText className="hero-title">Orthopedic Network News</TitleText>
          <NormalText secondary>
            We’re in the business of bettering patient's lives. We want to help your hospital improve your supply chain,
            leading to more effective orthopedic products and cheaper healthcare for your patients, empowering them to
            live the life they deserve.
          </NormalText>
        </AbstractPara>
        <ButtonsGroup>
          <a href={env.WEBAPP_LINK + 'auth/signup'}>
            <Button primary block>
              Create Account
            </Button>
          </a>
        </ButtonsGroup>
      </InnerContainer>
    </Container>
  </Background>
)

export default HomeAbstract
