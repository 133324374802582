import React from 'react'
import { HomeAbstract, HomeFindAPart, HomeNews, HomeProductsAndServices } from '../components/Home'
import Layout from '../components/layout'
import Pricings from '../components/Pricings/Pricings'

const IndexPage = () => (
  <Layout>
    <HomeAbstract />
    <HomeProductsAndServices />
    <HomeFindAPart />
    <HomeNews />
    <Pricings />
  </Layout>
)

export default IndexPage
