import { Container, Flex, NormalText } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import imgNews1 from '../../images/Home/news-img-1.svg'
import imgNews2 from '../../images/Home/news-img-2.svg'
import iconYear from '../../images/Icon/48px/91.svg'
import iconCase from '../../images/Icon/48px/Case.svg'
import iconCheck from '../../images/Icon/48px/Check.svg'
import iconHospital from '../../images/Icon/48px/Hospital.svg'
import Divider from '../Divider'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'

/////////////////////////
// items data
/////////////////////////
const summeryData = [
  {
    icon: iconYear,
    subtitle: "Trusted since '91",
  },
  {
    icon: iconHospital,
    subtitle: '+250 hospitals',
  },
  {
    icon: iconCase,
    subtitle: '+100,000 cases',
  },
  {
    icon: iconCheck,
    subtitle: 'Cited by medical professionals & journals',
  },
]
const newsData = [
  {
    title: "You're in good company",
    detail: 'We’re trusted by hospitals, manufacturers, the academic community, and many more.',
    img: imgNews1,
  },
  {
    title: "More data than you'll ever need",
    detail: 'Over 250 hospitals and more than 100,000 cases, all at your fingertips.',
    img: imgNews2,
  },
]
/////////////////////////
// styled components
/////////////////////////
const NewsHeader = styled(Flex)`
  flex-direction: column;
  align-items: center;

  margin-top: 120px;
  margin-bottom: 125px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-top: 72px;
    margin-bottom: 42px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 48px;
    margin-bottom: 42px;
  }

  div {
    max-width: 620px;
  }
`
const HeaderTitle = styled(NormalText)`
  margin-bottom: 32px;
  margin: '0 auto 32px auto';
  border-left: #0076fd 3px solid;

  padding-left: 20px;
  margin-bottom: 42px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 26px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding-left: 16px;
    margin-bottom: 26px;
  }
`
////////////////////////////////
const SummaryGrid = styled(Flex)`
  justify-content: center;
  margin-right: -40px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    grid-template-columns: repeat(4, 232px);
    margin-right: 0px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    grid-template-columns: 1fr 1fr;
    margin-right: -14px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    grid-template-columns: 148px 148px;
    margin-right: -14px;
  }
`
const GridGroup = styled(Flex)`
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    flex-direction: column;
    margin-bottom: -24px;
    flex: 1;
    margin-right: 14px;
  }
`

const ItemContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;

  width: 260px;
  margin-right: 40px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    width: 232px;
    margin-right: 0px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    width: 100%;
    margin-bottom: 24px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: 148px;
    margin-bottom: 24px;
  }
`

const ItemIcon = styled.div`
  border-radius: 50%;
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;

  margin-bottom: 32px;
  padding: 36px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 24px;
    padding: 28px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 18px;
    padding: 22px;
  }

  img {
    margin: 0;
    display: block;

    width: 80px;
    @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
      width: 64px;
    }
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      width: 52px;
    }
  }
`
////////////////////////////////
const TopPadding = styled.div`
  visibility: hidden;
  height: 120px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    height: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    height: 56px;
  }
`
const BottomPadding = styled.div`
  visibility: hidden;
  height: 120px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    height: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    height: 70px;
  }
`
const ParaGrid = styled.div`
  width: 100%;
  margin-bottom: -64px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: -14px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: -48px;
  }
`
const CustomRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;

  margin-bottom: 64px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 14px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 48px;
    flex-direction: column;
  }
`
const NewsComponent = styled.div<{ textAlign: 'left' | 'right' }>`
  max-width: 590px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 398px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
  }

  ${NormalText} {
    padding: ${props => (props.textAlign === 'left' ? '0 1em 0 0' : '0 0 0 1em')};
    justify-content: ${props => (props.textAlign === 'left' ? 'flex-start' : 'flex-end')};
    text-align: ${props => props.textAlign};
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      text-align: center;
    }
  }
  .para-title {
    margin-bottom: 28px;
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      margin-bottom: 20px;
    }
  }
`
const ImgContainer = styled.div`
  position: relative;

  img {
    width: 100%;
  }
  width: 408px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    width: 338px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: 100%;
  }
`

/////////////////////////
// Home News Class
/////////////////////////
const HomeNews: React.SFC = () => (
  <Container flexDirection="column">
    <NewsHeader>
      <HeaderTitle className="section-title">Orthopedic Network News</HeaderTitle>
      <NormalText secondary center>
        Where your business can stay updated on the latest in Orthopedics.
      </NormalText>
    </NewsHeader>
    <SummaryGrid>
      {summeryData.length &&
        summeryData
          .reduce<any[][]>((preVal, currVal, curIndex) => {
            if (curIndex % 2 === 0) {
              preVal.push([currVal])
            } else {
              preVal[Math.floor(curIndex / 2)].push(currVal)
            }
            return preVal
          }, [])
          .map((group, groupIndex) => {
            return (
              <GridGroup key={'New_row_' + groupIndex}>
                {group.map((sum, sumIndex) => (
                  <ItemContainer key={'key' + sumIndex}>
                    <ItemIcon>
                      <img src={sum.icon} />
                    </ItemIcon>
                    <NormalText className="medium-title" center style={{ alignSelf: 'stretch' }}>
                      {sum.subtitle}
                    </NormalText>
                  </ItemContainer>
                ))}
              </GridGroup>
            )
          })}
    </SummaryGrid>
    <TopPadding />
    <ParaGrid>
      {!!newsData.length &&
        newsData.map((newPage, newPageIndex) => (
          <CustomRow
            key={'news' + newPageIndex}
            flexDirection={newPageIndex % 2 ? 'row' : 'row-reverse'}
          >
            <ImgContainer>
              <img src={newPage.img} />
            </ImgContainer>
            <NewsComponent textAlign={newPageIndex % 2 ? 'right' : 'left'}>
              <NormalText className="para-title heading">{newPage.title}</NormalText>
              <NormalText dark>{newPage.detail}</NormalText>
            </NewsComponent>
          </CustomRow>
        ))}
    </ParaGrid>
    <BottomPadding />
    <Divider />
  </Container>
)

export default HomeNews
