import { Container, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import background from '../../images/Home/find-a-part-bg.jpg'
import imgData from '../../images/Home/find-a-part-data.svg'
import imgSearch from '../../images/Home/find-a-part-search.svg'
import imgUpdate from '../../images/Home/find-a-part-update.svg'
import Divider from '../Divider'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'

/////////////////////////
// paragraphs data
/////////////////////////
const findAPartData = [
  {
    title: 'An Effortless Search Engine',
    detail:
      "We've created a search engine that'll read your mind. Orthopedic parts can be difficult to find, thanks to our advanced search capabilities and part suggestions, you'll spend less time searching and more time on your business",
    img: imgSearch,
  },
  {
    title: 'A Database of +1,000,000 Parts',
    detail:
      "We don't miss details. Our database includes part information about selling prices, brands, classifications, materials, and much more.",
    img: imgData,
  },
  {
    title: 'Designed for a Dynamic Industry',
    detail:
      "We stay one step ahead. In an industry that's constantly changing, we update our data on a quarterly basis, ensuring that the data you're receiving is current.",
    img: imgUpdate,
  },
]
/////////////////////////
// styled components
/////////////////////////
const Background = styled(Flex)`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
`
const HeaderTitle = styled.div`
  max-width: 620px;

  margin-top: 330px;
  margin-bottom: 80px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-top: 212px;
    margin-bottom: 66px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 116px;
    margin-bottom: 36px;
  }

  .main-title {
    display: flex;
    align-items: center;
    border-left: #0076fd 3px solid;

    padding-left: 20px;
    margin-bottom: 24px;
    @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      padding-left: 16px;
      margin-bottom: 16px;
    }
  }
`
const RowsContainer = styled.div`
  width: 100%;
  margin-bottom: -40px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: -36px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: -42px;
  }
`

const RowWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 36px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 42px;
  }
`

const CustomDivider = styled(Divider)`
  visibility: hidden;
  margin-top: 0;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    visibility: visible;
    margin-top: 20px;
  }

  &.deactivate {
    display: none;
  }
`
const CustomRow = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    flex-direction: column;
  }
`

const ItemContainer = styled.div`
  img {
    width: 100%;
  }
  width: 510px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    width: 338px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

const Paragraph = styled.div<{ textAlign: 'left' | 'right' }>`
  max-width: 590px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 424px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
  }

  ${NormalText} {
    padding: ${props => (props.textAlign === 'left' ? '0 1em 0 0' : '0 0 0 1em')};
    justify-content: ${props => (props.textAlign === 'left' ? 'flex-start' : 'flex-end')};
    text-align: ${props => props.textAlign};
    @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
      text-align: center;
    }
  }
`
const BottomPadding = styled.div`
  visibility: hidden;
  height: 120px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    height: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    height: 48px;
  }
`

/////////////////////////
// Home Find a Part Class
/////////////////////////
const HomeFindAPart: React.SFC = () => (
  <Background>
    <Container flexDirection="column">
      <HeaderTitle>
        <NormalText className="section-title main-title">Find a Part</NormalText>
        <NormalText secondary>
          We built a database just for you, +1,000,000 parts updated on a quarterly basis offering
          you market insight on price & where each part fits in the GIC categorization.
        </NormalText>
      </HeaderTitle>
      <RowsContainer>
        {!!findAPartData.length &&
          findAPartData.map((param, index) => (
            <RowWrapper key={'param' + index}>
              <CustomRow flexDirection={index % 2 ? 'row' : 'row-reverse'}>
                <ItemContainer>
                  <img src={param.img} />
                </ItemContainer>
                <Paragraph textAlign={index % 2 ? 'right' : 'left'}>
                  <NormalText className="heading" marginBottom="24px">
                    {param.title}
                  </NormalText>
                  <NormalText dark>{param.detail}</NormalText>
                </Paragraph>
              </CustomRow>
              <CustomDivider className={index + 1 < findAPartData.length ? '' : 'deactivate'} />
            </RowWrapper>
          ))}
      </RowsContainer>
      <BottomPadding />
    </Container>
  </Background>
)

export default HomeFindAPart
